export const applicationTitle = 'S&P Global - InvestorAccess';
export const muniApplicationTitle = 'S&P Global - Muni';

export const xsrfTokenStorageKey = 'app_xsrf_token';
export const pomJwtTokenStorageKey = 'pom_token';
export const websocketGatewayTokenStorageKey = 'websocketGateway_token';
export const jwtTokenV2StorageKey = 'jwt_token_v2';
export const northstarAlertsDesktopNotificationsEnabled = 'northstar-alerts-desktop-notifications-enabled';
export const appSessionTimeoutStorageKey = 'app_sessionTimedOut';
export const disableDesktopNotificationsKey = '__DISABLE_DESKTOP_NOTIFICATIONS__';

export enum JwtTokenType {
    Pom,
    WebsocketGateway,
    JwtTokenV2
}

export enum TimeoutState {
    TimeoutCleared = 'Timeout cleared'
}

export type TimeoutHandle = number | TimeoutState.TimeoutCleared;

export enum TokenState {
    Expired = 'expired',
    Absent = 'absent'
}

export type TokenValue = string | TokenState.Expired;
