declare module '@angular/core' {
    interface ModuleWithProviders<T = any> {
        ngModule: Type<T>;
        providers?: (Provider | EnvironmentProviders)[];
    }
}

import { ActionReducerMap, StoreModule } from '@ngrx/store';
import {
    CustomSerializer,
    State,
    metaReducers,
    reducers
} from './reducers';
import { ENVIRONMENT_CONFIG, EnvironmentConfig, SettingsService } from '@ipreo/northstar';
import { InjectionToken, NgModule } from '@angular/core';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';

import { APP_INITIALIZER_PROVIDER } from './app-initializer.helper';
import { AcceptTermsEffect } from '@core/@state/effects/terms-of-use.effect';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChangePasswordEffect } from '@core/@state/effects/change-password.effect';
import { ConfirmProfileEffect } from '@core/@state/effects/confirm-profile.effect';
import { CoreComponentsModule } from '@core/core-components/core-components.module';
import { CoreModule } from '@core/core.module';
import { EffectsModule } from '@ngrx/effects';
import { HighlightModule } from 'ngx-highlightjs';
import { LoggerEffect } from '@core/@state/effects/logger.effect';
import { LoginEffect } from '@core/@state/effects/login.effect';
import { LogoutEffect } from '@core/@state/effects/logout.effect';
import { PlatformWebSocketsConnector } from '@core/live-update/websockets/platform-websockets-connector';
import { PoliciesModule } from './modules/policies/policies.module';
import { ResetPasswordEffect } from '@core/@state/effects/reset-password.effect';
import { SharedModule } from './modules/shared/shared.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SupportEffect } from '@core/@state/effects/support.effect';
import { SyncUserSettingsEffect } from '@core/@state/effects/sync-user-settings.effect';
import { UserProfileEffect } from '@core/@state/effects/user-profile.effect';
import { UserSettingsPageEffect } from '@core/@state/effects/user-settings-page.effect';
import { VersionCheckEffect } from '@core/@state/effects/version-check.effect';
import { WebsocketConnectionEffect } from '@core/@state/effects/websocket-connection.effect';
import { environment } from '@environments/environment';
import json from 'highlight.js/lib/languages/json';
import { EquityUserSettingsEffect } from '@core/@state/effects/equity-user-settings.effect';

export const reducerToken = new InjectionToken<ActionReducerMap<State>>('Buyside Platform Reducers');

export const reducerProvider = [
    { provide: reducerToken, useValue: reducers }
];

export function hljsLanguages(): { name: string, func: string}[] {
    return [{ name: 'json', func: json }];
}

@NgModule({
    declarations: [
        AppComponent
    ],
    /*
        Please, do not add unnecessary dependencies
    */
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        CoreComponentsModule,
        StoreModule.forRoot(reducerToken, {
            metaReducers,
            // runtimeChecks: {
            //     strictStateImmutability:true,
            //     strictActionImmutability:true,
            // }
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            }
        }),
        StoreRouterConnectingModule.forRoot(),
        StoreDevtoolsModule.instrument({
            name: 'BuySide 2.0 Store Dev Tools',
            logOnly: environment.production,
            maxAge: 25
        }),
        EffectsModule.forRoot([
            LogoutEffect,
            LoginEffect,
            VersionCheckEffect,
            SupportEffect,
            UserProfileEffect,
            ResetPasswordEffect,
            ChangePasswordEffect,
            ConfirmProfileEffect,
            SyncUserSettingsEffect,
            UserSettingsPageEffect,
            AcceptTermsEffect,
            LoggerEffect,
            WebsocketConnectionEffect,
            EquityUserSettingsEffect
        ]),
        PoliciesModule,
        HighlightModule.forRoot({
            languages: hljsLanguages,
            config: { tabReplace: '    ' }
        })
    ],
    providers: [
        APP_INITIALIZER_PROVIDER,
        {
            provide: ENVIRONMENT_CONFIG,
            useValue: {
                northstar: {
                    host: {
                        api: (window as any).buySideConfiguration.ipreoApiBaseUrl,
                        ws: `${(window as any).buySideConfiguration.ipreoSocketsUrl}/connect`,
                        hub: null,
                        registry: null
                    },
                    env: (window as any).buySideConfiguration.northstarAlertsEnvironment,
                    cupcakeMajorVersion: 2,
                },
                params: {
                    apiHost: (window as any).buySideConfiguration.muniBffUrl,
                    platform: 'buyside',
                    dealUpdateChannel: (window as any).buySideConfiguration.muniDealUpdatesSocketsChannel,
                    groupFollowUpdateChannel: (window as any).buySideConfiguration.muniGroupFollowUpdateChannel,
                    assignmentsUpdateChannel: (window as any).buySideConfiguration.muniAssignmentsUpdateChannel,
                    muniApiHost: (window as any).buySideConfiguration.muniApiHost,
                    buySideEnv: (window as any).buySideConfiguration.environment,
                    supportGroupEmail: (window as any).buySideConfiguration.supportGroupEmail,
                }
            } as EnvironmentConfig
        },
        { provide: SettingsService, useValue: {} },
        { provide: RouterStateSerializer, useClass: CustomSerializer },
        PlatformWebSocketsConnector,
        reducerProvider,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

/* PRE-BUILD SCRIPT HASH: 1728503403444 */
