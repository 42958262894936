export const generateGUID = (): string => {
    const s4 = (): string =>
        Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
};

export const isRelativeBffUrl = (url: string): boolean => {
    if (url === null || url === undefined) {
        return false;
    }

    return url.startsWith('api/') || url.startsWith('/api/');
};

export const composeElementSelector = (el: Element): string => {
    let selector: string = null;

    const mapClassList = classList => Array.from(classList).map(className => `.${className}`).join('');

    if (el && el.parentElement && el.parentElement.classList) {
        selector = el.parentElement.tagName + mapClassList(el.parentElement.classList) + ' > ';
    }

    if (el && el.classList) {
        selector += el.tagName + mapClassList(el.classList);
    }

    return selector;
};

export const isNullOrUndefined = (x: any): boolean => x === undefined || x === null;

export const deepClone = <T>(o: T): T => <T>JSON.parse(JSON.stringify(o));
